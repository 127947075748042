.container {
  width: 90%;
  margin-bottom: 25px;
}

.game .title {
  margin-top: 25px;
}

.game .question-text {
  margin-left: 25px;
  margin-right: 25px;

  p {
    font-size: 15pt;
  }
}

.inner-div {
  min-height: 100px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.inner-div p {
  font-size: 13pt;
  font-weight: bold;
}

.game-submit button{
  margin-top: 30px;
}

.answered {
  margin-top: 10%;
}

.answered h2 {
  font-size: 30px;
  text-align: center;
}

.answered h3 {
  font-size: 18px;
  text-align: center;
}