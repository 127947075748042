.navbar-project-title {
  position: absolute;
  top: 16px;
  left: 50%;
  transform: translateX(-50%);
}

.table-align {
  text-align: right;
}

.logo {
  max-width: 200px;
}

.title-font {
  text-align: center;
  font-size: 30px;
  font-weight: 900;
}

.title-sub-font {
  text-align: center;
  font-size: 28px;
}

[data-bs-theme="dark"] {
  .header-logo {
    content: url("/public/images/WiFiFox logo light.png");
  }
}

[data-bs-theme="light"] {
  .header-logo {
    content: url("/public/images/WiFiFox logo dark.png");
  }
}